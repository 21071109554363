<template>
  <v-container class="my-5">
    <v-row>
      <v-col cols="12">
        <p v-if="subtitle" :class="`${subtitleColor}--text`" class="text-center">
          {{ $t(`${subtitle}`) }}
        </p>
        <h1 :class="`${titleColor}--text`" class="text-center display-1">
          {{ $t(`${title}`) }}
        </h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Headline1',
  components: {
    //
  },
  props: {
    title: {
      type: String,
      default: 'title',
    },
    subtitle: {
      type: String,
      default: 'subtitle',
    },
    titleColor: {
      type: String,
      default: 'primary',
    },
    subtitleColor: {
      type: String,
      default: 'black',
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
