<template>
  <v-container fluid
               class="pa-0"
               :style="`background: ${backgroundTopColor}; background: linear-gradient(to bottom, ${backgroundTopColor}, ${backgroundBottomColor});`">
    <v-container class="py-16">
      <v-row justify="center">
        <template v-for="(box, index) in boxes">
          <v-col cols="12" :sm="boxColSm" :md="boxColMd" :lg="boxColLg" :key="`bx${index}`">
            <v-card width="100%"
                    :elevation="0"
                    color="transparent"
                    class="py-4 mx-auto mb-8"
                    :max-width="boxMaxWidht">
              <v-row class="ma-0 text-center fill-height align-content-space-between">
                <v-col cols="12">
                  <div class="text-center">
                    <v-icon :color="dark ? 'white' : 'primary'"
                            size="72"
                            class="mb-3">
                      mdi-{{ box.icon }}
                    </v-icon>
                  </div>
                  <div v-html="box.title"
                       :class="dark ? `white--text text-${titleSize} font-weight-bold mb-3` : `text-${titleSize} font-weight-bold mb-2`"></div>
                  <div v-html="box.description" class="text--lighten-1 mb-2" :class="dark ? 'white--text' : ''"></div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </template>
        <v-col v-if="button.isVisible" cols="12" :class="`text-${button.align}`">
          <v-btn color="primary"
                 large
                 rounded
                 @click="onButtonClick(button)">
            {{ button.text }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'IconBox2',
  components: {
    //
  },
  props: {
    backgroundBottomColor: {
      type: String,
      default: '#F5F5F5',
    },
    backgroundTopColor: {
      type: String,
      default: '#F5F5F5',
    },
    boxes: {
      type: Array,
      default() {
        return [
          {
            description: 'Minden megvásárolt készülék rendszerét gyári állapotra visszaállítjuk, törölve róla '
              + 'minden adatot. Nyugodt lehetsz, adataid nem fognak harmadik félhez kerülni.',
            icon: 'shield-crown',
            title: 'Biztonságos',
          },
          {
            description: 'Ahogy beérkezett a készüléked, ellenőrizzük és 24 órán belül utaljuk a vételárát.',
            icon: 'clock-fast',
            title: 'Gyors és egyszerű',
          },
          {
            description: 'A megvásárolt készüléket megjavítjuk, felújítjuk annak érdekében, hogy visszakerüljenek '
              + 'a körfogásba. Mindezzel közösen hozzájárulunk a környezetvédelemhez.',
            icon: 'leaf',
            title: 'Zöld',
          },
        ];
      },
    },
    boxColMd: {
      type: Number,
      default: 4,
    },
    boxColLg: {
      type: Number,
      default: 3,
    },
    boxColSm: {
      type: Number,
      default: 6,
    },
    boxMaxWidht: {
      type: Number,
      default: 300,
    },
    button: {
      type: Object,
      default() {
        return {
          align: 'center',
          isExternal: false,
          isVisible: false,
          link_params: { partnerName: 'kalkulacio' },
          link_url: 'partnerHome-cashback',
          text: 'Kalkuláció',
        };
      },
    },
    dark: {
      type: Boolean,
      default: false,
    },
    titleSize: {
      type: String,
      default: 'h6',
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    onButtonClick(button) {
      if (button.isExternal) {
        window.open(button.link_url, '_blank');
      } else {
        if (button.link_params) {
          this.$router.push({ name: button.link_url, params: button.link_params });
        } else {
          this.$router.push({ name: button.link_url });
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
