<template>
  <v-container>
    <!-- KAMPÁNY VÁLASZTÓ START -->
    <headline1 subtitle="firstStep" title="deviceToBuy" id="deviceToBuy" v-if="$store.getters.getAppCampaignsLength > 1"></headline1>
      <v-container class="my-5"
                   v-if="$store.getters.getAppCampaignsLength > 1">
        <v-row justify="center">
          <v-card color="transparent" elevation="0" width="100%" max-width="600">
            <v-form v-model="campaignForm">
              <v-card-text>
                <template v-if="$store.state.appStore.appSelectedPartner.selling_product_group_enabled === 1">
                  <v-select v-model="selectedSellingProductGroup"
                            :label="$t('labels.productGroup')"
                            :rules="[inputRules.requiredField, inputRules.selectRule]"
                            :hint="$t('hints.requiredField')"
                            :items="selectFieldOptions.sellingProductGroups"
                            item-value="productGroupId"
                            item-text="name"
                            persistent-hint
                            return-object
                            outlined
                            @change="onSellingProductGroupSelectorChange"
                            class="bigInput mb-3">
                  </v-select>
                  <v-select v-model="selectedCampaign"
                            :label="$t('labels.productType')"
                            :hint="$t('hints.requiredField')"
                            :items="$store.state.appStore.appCampaigns"
                            item-text="trader_device_name"
                            item-value="bb_campaign_id"
                            return-object
                            persistent-hint
                            outlined
                            :disabled="$store.state.appStore.appCampaigns.length === 0"
                            @change="scrollToPosition('deviceToSellImei')">
                    <template v-slot:append>
                      <v-progress-circular v-if="sellingProductTypesLoading"
                                           size="20"
                                           color="primary"
                                           width="2"
                                           indeterminate>
                      </v-progress-circular>
                    </template>
                  </v-select>
                </template>
                <template v-else>
                  <v-select v-model="selectedCampaign"
                            :label="$t('labels.productType')"
                            :hint="$t('hints.requiredField')"
                            :items="$store.state.appStore.appCampaigns"
                            item-text="campaign_code"
                            item-value="bb_campaign_id"
                            return-object
                            persistent-hint
                            outlined
                            @change="scrollToPosition('deviceToSellImei')">
                  </v-select>
                </template>
              </v-card-text>
            </v-form>
          </v-card>
        </v-row>
      </v-container>
    <!-- KAMPÁNY VÁLASZTÓ END -->
    <headline1 :subtitle="$store.getters.getAppCampaignsLength > 1 ? 'secondStep' : 'firstStep'"
               title="deviceToSell" id="deviceToSellImei"></headline1>
    <!-- TERMÉKCSOPORT VÁLASZTÓ START -->
    <template v-if="$store.state.appStore.appSelectedPartner.multiple_product_group_enabled === 1">
      <v-container class="my-5">
        <v-row justify="center">
          <v-card color="transparent" elevation="0" width="100%" max-width="600">
            <v-form v-model="productGroupForm">
              <v-card-text>
                <template v-if="$store.state.appStore.appSelectedPartner.product_group_radio_button_enabled === 0">
                  <v-select v-model="selectedProductGroup"
                            :label="$t('labels.productGroup')"
                            :rules="[inputRules.requiredField, inputRules.selectRule]"
                            :hint="$t('hints.requiredField')"
                            :items="selectFieldOptions.productGroups"
                            item-value="productGroupId"
                            item-text="productGroupName"
                            persistent-hint
                            return-object
                            outlined
                            @change="onProductGroupSelectorChange"
                            class="bigInput">
                  </v-select>
                </template>
                <template v-else>
                  <v-radio-group v-model="selectedProductGroup" class="ma-0 pa-0" hide-details>
                    <template v-for="(productGroup, pindex) in selectFieldOptions.productGroups">
                      <v-hover :key="`p${productGroup.productGroupId}hov${pindex}`"
                               v-slot="{ hover }">
                        <v-list-item :key="`p${productGroup.productGroupId}id${pindex}`"
                                     class="elevation-1 mb-3"
                                     :class="hover ? 'grey lighten-4' : ''">
                          <v-list-item-title>
                            <v-radio :value="productGroup"
                                     style="height: 56px;"
                                     class="deviceFinderRadio">
                              <template v-slot:label>
                                <div class="ml-2">
                                  {{ productGroup.productGroupName }}
                                </div>
                              </template>
                            </v-radio>
                          </v-list-item-title>
                          <v-list-item-icon>
                            <v-img :src="`${publicPath}images/productGroup${productGroup.productGroupId}.png`"
                                   aspect-ratio="1"
                                   alt="productGroup" />
                          </v-list-item-icon>
                        </v-list-item>
                      </v-hover>
                      <!--v-divider :key="`p${productGroup.productGroupId}div${pindex}`"
                                 class="mx-4"
                                 v-if="pindex < selectFieldOptions.productGroups.length - 1"></v-divider-->
                    </template>
                  </v-radio-group>
                </template>
                <template v-if="showProductGroupNextButton">
                  <v-btn class="primary"
                         @click="onProductGroupSelectorNextButtonClick"
                         x-large
                         block
                         :disabled="!productGroupForm">
                    {{ $t('button.next') }}
                  </v-btn>
                </template>
              </v-card-text>
            </v-form>
          </v-card>
        </v-row>
      </v-container>
    </template>
    <!-- TERMÉKCSOPORT VÁLASZTÓ END -->
    <template v-if="showImeiFinder">
      <!-- IMEI KERESŐ START -->
      <v-container class="my-5">
        <v-row justify="center">
          <v-card color="transparent" elevation="0" width="100%" max-width="600">
            <v-form v-model="imeiForm">
              <v-card-text>
                <v-text-field v-model="deviceToSell.deviceIdentifier"
                              :label="$t('labels.deviceImei')"
                              :rules="inputRules.imeiRule"
                              :hint="$t('hints.imeiHint')"
                              persistent-hint
                              counter="15"
                              maxlength="15"
                              append-icon="mdi-magnify"
                              outlined
                              clearable
                              @click:clear="resetDeviceFinder"
                              class="bigInput"
                              :disabled="searching || !campaignForm">
                </v-text-field>
                <v-btn class="primary"
                       @click="callGetDeviceByImei(deviceToSell.deviceIdentifier)"
                       x-large
                       block
                       :disabled="!imeiSearchButtonIsActive">
                  {{ $t('button.search') }}
                </v-btn>
              </v-card-text>
            </v-form>
          </v-card>
        </v-row>
      </v-container>
      <!-- IMEI KERESŐ END -->
      <!-- EREDMÉNY SZÖVEG START -->
      <v-container id="deviceToSellNoResult">
        <v-row>
          <template v-if="!isFinished">
            <v-col cols="12" class="text-center">
              <div>
                {{ $t('deviceFinder.generalText') }}
              </div>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12" class="text-center" v-if="deviceStatus !== $HTTP_OK">
              <div class="text-h4 mb-6">
                {{ $t('deviceFinder.noResultTextTitle') }}
              </div>
              <div class="text-subtitle-1 mb-6">
                {{ $t('deviceFinder.noResultTextSubtitle') }}
              </div>
            </v-col>
          </template>
        </v-row>
      </v-container>
      <!-- EREDMÉNY SZÖVEG END -->
    </template>
    <!-- KÉSZÜLÉK VÁLASZTÓ START -->
    <v-container class="pa-0" v-if="isFinished && deviceStatus !== $HTTP_OK">
      <v-container id="devicePicker">
        <v-row justify="center">
          <v-card color="transparent" elevation="0" width="100%" max-width="600">
            <v-form v-model="deviceForm">
              <v-card-text>
                <v-autocomplete v-model="deviceSelector.brand"
                                :items="selectFieldOptions.brands"
                                item-value="id"
                                item-text="name"
                                :label="$t('labels.brand')"
                                :rules="[inputRules.requiredField, inputRules.selectRule]"
                                :hint="$t('hints.requiredField')"
                                :no-data-text="$t('hints.noDataText')"
                                :search-input.sync="searchInputBrand"
                                persistent-hint
                                outlined
                                return-object
                                class="mb-3"
                                required
                                @change="onBrandInputChange">
                </v-autocomplete>
                <v-autocomplete v-model="deviceSelector.productTypeFull"
                                :items="selectFieldOptions.productTypes"
                                item-text="deviceName"
                                item-value="productTypeId"
                                :label="$t('labels.productType')"
                                :rules="[inputRules.requiredField, inputRules.selectRule]"
                                :hint="$t('hints.requiredField')"
                                :no-data-text="$t('hints.noDataText')"
                                :search-input.sync="searchInputProductType"
                                @change="SetSelectFieldOptionsModelDatas(deviceSelector.productTypeFull.productTypeId)"
                                persistent-hint
                                outlined
                                return-object
                                class="mb-3"
                                required>
                </v-autocomplete>
                <v-select v-if="selectFieldOptions.colors.length > 0"
                          v-model="deviceToSell.colorId"
                          :items="selectFieldOptions.colors"
                          item-text="name"
                          item-value="id"
                          :label="$t('labels.deviceColor')"
                          :rules="[inputRules.requiredField, inputRules.selectRule]"
                          :hint="$t('hints.requiredField')"
                          :no-data-text="$t('hints.noDataText')"
                          persistent-hint
                          outlined
                          class="mb-3"
                          required>
                </v-select>
                <v-select v-if="selectFieldOptions.storageCapacity.length > 0"
                          v-model="deviceToSell.storageCapacityId"
                          :items="selectFieldOptions.storageCapacity"
                          item-text="name"
                          item-value="id"
                          :label="$t('labels.deviceStorageCapacity')"
                          :rules="[inputRules.requiredField, inputRules.selectRule]"
                          :hint="$t('hints.requiredField')"
                          :no-data-text="$t('hints.noDataText')"
                          persistent-hint
                          outlined
                          class="mb-3"
                          required>
                </v-select>
                <template v-if="showImeiTextInput">
                  <v-text-field v-model="deviceToSell.deviceIdentifier"
                                :label="$t('labels.deviceImei')"
                                :rules="inputRules.imeiRule"
                                :hint="$t('hints.imeiHint')"
                                persistent-hint
                                counter="15"
                                maxlength="15"
                                outlined>
                  </v-text-field>
                </template>
                <v-row v-show="$store.state.appStore.appSelectedPartner.customer_selling_price_enabled === 1">
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="deviceToSell.customerSellingPriceFrom"
                                  :label="$t('labels.customerSellingPriceFrom')"
                                  :rules="[inputRules.numberValidator]"
                                  :hint="$t('hints.optionalField')"
                                  persistent-hint
                                  suffix="Ft"
                                  outlined>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="deviceToSell.customerSellingPriceTo"
                                  :label="$t('labels.customerSellingPriceTo')"
                                  :rules="[inputRules.numberValidator]"
                                  :hint="$t('hints.optionalField')"
                                  persistent-hint
                                  suffix="Ft"
                                  outlined>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-btn color="primary"
                       @click="onDeviceSelectorNextButtonClick"
                       style="height: 56px;"
                       x-large
                       block
                       :disabled="!(deviceForm && selectedCampaign)">
                  {{ $t('button.next') }}
                </v-btn>
              </v-card-text>
            </v-form>
          </v-card>
        </v-row>
      </v-container>
    </v-container>
    <!-- KÉSZÜLÉK VÁLASZTÓ END -->
    <!-- ÁLLAPOT JELZŐK START -->
    <v-container v-if="deviceState">
      <headline1 :subtitle="$store.getters.getAppCampaignsLength > 1 ? 'thirdStep' : 'secondStep'" title="calculatorStepper.failures" id="deviceState"></headline1>
      <v-container class="py-5">
        <v-row>
          <v-col cols="12" class="text-center">
            <div class="text-h4 mb-6">
              {{ $t('yourDevice') }}: {{ deviceToSell.brandName }} {{ deviceToSell.deviceName }}
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="py-5">
        <v-row v-show="this.$store.state.appStore.appSelectedPartner.customer_selling_price_enabled === 1"
               style="max-width: 600px;" justify="center" class="mx-auto">
          <v-col cols="12" sm="6">
            <v-text-field v-model="deviceToSell.customerSellingPriceFrom"
                          :label="$t('labels.customerSellingPriceFrom')"
                          :rules="[inputRules.numberValidator]"
                          :hint="$t('hints.optionalField')"
                          persistent-hint
                          suffix="Ft"
                          outlined>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field v-model="deviceToSell.customerSellingPriceTo"
                          :label="$t('labels.customerSellingPriceTo')"
                          :rules="[inputRules.numberValidator]"
                          :hint="$t('hints.optionalField')"
                          persistent-hint
                          suffix="Ft"
                          outlined>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <template v-for="(item, index) in deviceState.data">
            <v-col cols="12" sm="6" md="4" :key="`${item.typeCode}${index}`">
              <v-card max-width="320" class="fill-height mx-auto">
                <v-card-title>
                  {{ item.typeName }}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="px-0">
                  <v-radio-group v-model="damages[item.typeCode]" class="ma-0 pa-0" hide-details>
                    <template v-for="(status, sindex) in item.data">
                      <v-hover :key="`s${status.statusId}hov${sindex}`"
                               v-slot="{ hover }">
                        <v-list-item :key="`s${status.statusId}id${sindex}`"
                                     :class="hover ? 'grey lighten-4' : ''">
                          <v-list-item-title>
                            <v-radio :value="status"
                                     style="height: 56px;"
                                     class="deviceFinderRadio">
                              <template v-slot:label>
                                <div class="ml-2">
                                  {{ status.statusName }}
                                </div>
                              </template>
                            </v-radio>
                          </v-list-item-title>
                          <v-list-item-icon>
                            <v-tooltip top>
                              <template v-slot:activator="{on}">
                                <v-icon v-on="on">
                                  mdi-information
                                </v-icon>
                              </template>
                              <div style="max-width: 240px;">
                                {{ status.statusHint }}
                              </div>
                            </v-tooltip>
                          </v-list-item-icon>
                        </v-list-item>
                      </v-hover>
                      <v-divider :key="`s${status.statusId}div${sindex}`"
                                 class="mx-4"
                                 v-if="sindex < item.data.length - 1"></v-divider>
                    </template>
                  </v-radio-group>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
        <v-row justify="center" class="py-5">
          <v-btn color="primary"
                 @click="callCreateNewCalcData"
                 style="height: 56px;"
                 x-large
                 class="mx-auto"
                 :disabled="!createCalculationButtonIsActive">
            {{ $t('button.next') }}
          </v-btn>
        </v-row>
      </v-container>
    </v-container>
    <!-- ÁLLAPOT JELZŐK END -->
  </v-container>
</template>

<script>
import Headline1 from 'src/components/headlines/Headline1.vue';

export default {
  name: 'DeviceFinder',
  components: {
    Headline1,
  },
  props: {
    //
  },
  data() {
    return {
      //
    };
  },
  async beforeMount() {
    console.log('DeviceFinder appSelectedPartner: ', this.$store.state.appStore.appSelectedPartner);
    if (this.$store.state.appStore.appSelectedPartner.multiple_product_group_enabled === 1) {
      await this.SetSelectFieldOptionsProductGroupsAvsId();
    }
    if (this.$store.state.appStore.appSelectedPartner.imei_finder_enabled === 0) {
      this.isFinished = true;
      this.deviceStatus = this.$HTTP_MY_ERROR;
      await this.SetSelectFieldOptionsBrands();
    }
    if (this.$store.state.appStore.appSelectedPartner.selling_product_group_enabled === 1) {
      await this.SetSelectFieldOptionsSellingProductGroupsAvsId();
    }
    if (this.$store.state.appStore.appCampaigns.length === 1) {
      this.selectedCampaign = this.$store.state.appStore.appCampaigns[0];
    }
  },
  methods: {
    //
  },
  computed: {
    createCalculationButtonIsActive() {
      let selectedDamagesLength = Object.keys(this.damages).length;
      if (this.deviceState) {
        return selectedDamagesLength === this.deviceState.data.length;
      } else {
        return false;
      }
    },
    imeiSearchButtonIsActive() {
      if (this.selectedCampaign) {
        return !(!this.deviceToSell.deviceIdentifier || this.deviceToSell.deviceIdentifier === '' || this.deviceToSell.deviceIdentifier.length !== 15 || this.searching);
      } else {
        return false;
      }
    },
    showImeiFinder() {
      if (this.$store.state.appStore.appSelectedPartner.multiple_product_group_enabled === 1) {
        if (this.$store.state.appStore.appSelectedPartner.imei_finder_enabled === 1) {
          return this.selectedProductGroup.productGroupId && this.selectedProductGroup.productGroupId === 1;
        } else {
          return false;
        }
      }
      return this.$store.state.appStore.appSelectedPartner.imei_finder_enabled === 1;
    },
    showImeiTextInput() {
      if (this.$store.state.appStore.appSelectedPartner.multiple_product_group_enabled === 1) {
        return this.selectedProductGroup.productGroupId && this.selectedProductGroup.productGroupId === 1;
      }
      return true;
    },
    showProductGroupNextButton() {
      if (this.$store.state.appStore.appSelectedPartner.imei_finder_enabled === 1) {
        return this.selectedProductGroup.productGroupId && this.selectedProductGroup.productGroupId !== 1;
      }
      return true;
    },
  },
};
</script>

<style scoped>

</style>
