<template>
  <v-container>
    <v-slide-group v-model="selected"
                   show-arrows
                   center-active>
      <template v-for="(item, index) in listItems">
        <v-slide-item :key="index" v-slot="{ active, toggle }">
          <v-img :src="`${publicPath}${item}`"
                 aspect-ratio="1"
                 width="160"
                 class="cursor-pointer zoomObj mx-4"
                 @click="toggle"
                 contain />
        </v-slide-item>
      </template>
    </v-slide-group>
  </v-container>
</template>

<script>
export default {
  name: 'SlideGroup',
  components: {
    //
  },
  props: {
    listItems: {
      type: Array,
      default() {
        return [
          'images/placeholder2.jpg',
          'images/placeholder3.jpg',
          'images/placeholder4.jpg',
          'images/placeholder5.jpg',
          'images/placeholder6.jpg',
          'images/placeholder2.jpg',
          'images/placeholder3.jpg',
          'images/placeholder4.jpg',
          'images/placeholder5.jpg',
          'images/placeholder6.jpg',
        ];
      },
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
