<template>
  <v-container :fluid="contentBox.isFluid"
               class="pa-0"
               :style="`background-color: ${contentBox.backgroundColor};`">
    <v-container class="py-16 px-6">
      <v-row :align="contentBox.contentValign">
        <v-col cols="12" sm="6" :md="contentBox.colText" :order="contentBox.order === 'imgToRight' ? 1 : 2">
          <div v-html="contentBox.content" :class="`text-${contentBox.textAlign} mb-8`"></div>
          <div :class="`text-${contentBox.button.align}`">
            <v-btn v-if="contentBox.button.isVisible"
                   color="primary"
                   large
                   rounded
                   @click="onButtonClick">
              {{ contentBox.button.text }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" sm="6" :md="contentBox.colImg" :order="contentBox.order === 'imgToRight' ? 1 : 1">
          <template v-if="contentBox.useVideo">
            <video controls muted width="100%">
              <source v-for="(video, vindex) in contentBox.video"
                      :src="`${publicPath}${video.url}`"
                      :type="`video/${video.type}`"
                      :key="vindex">
              Your browser does not support HTML5 video.
            </video>
          </template>
          <template v-else>
            <v-row>
              <template v-for="(image, index) in contentBox.images">
                <v-col :cols="index === 0 ? 12 : 6" :key="index">
                  <v-img :src="`${publicPath}${image}`" />
                </v-col>
              </template>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'ContentBox1',
  components: {
    //
  },
  props: {
    contentBox: {
      type: Object,
      default() {
        return {
          button: {
            align: 'left',
            isExternal: false,
            isVisible: true,
            link_url: 'Home',
            text: 'Érdekel',
          },
          content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec orci rhoncus, maximus eros et, '
            + 'suscipit ipsum. Aliquam a quam vel arcu luctus suscipit. Sed pharetra, elit non placerat auctor, mi '
            + 'nulla sodales libero, sed vulputate odio nisl venenatis odio. Morbi porta sed arcu tempus posuere. '
            + 'Proin in tortor nisi. Quisque velit nisi, mollis vitae enim eget, pharetra suscipit velit. Suspendisse '
            + 'pretium a urna non venenatis. Etiam eleifend lectus sed mi aliquet, non tincidunt magna gravida. '
            + 'Pellentesque sed orci quam. Suspendisse consectetur erat malesuada, ultricies orci vitae, rhoncus '
            + 'nibh. Aliquam sit amet aliquam est. Suspendisse eu ornare justo. Quisque imperdiet fringilla erat, '
            + 'vel sagittis libero aliquet nec. Donec vitae erat in nibh commodo tincidunt sed id massa.</p>'
            + '<p>In erat tortor, fermentum in facilisis ut, condimentum et turpis. Praesent porttitor nulla quis '
            + 'nisi pharetra, interdum tristique massa dictum. Sed quis orci eget velit porttitor fringilla sed '
            + 'ut felis. Nullam porta purus id dolor pretium, a consectetur eros cursus. Curabitur leo nibh, commodo '
            + 'id interdum ut, malesuada sed nisl. Duis aliquet vehicula nisl. Ut pretium, mi id malesuada vehicula, '
            + 'enim quam mollis lorem, id dictum nibh ex a purus. Donec tristique pellentesque ex, ac gravida leo '
            + 'vestibulum ut. Integer rhoncus lectus sed tellus lobortis molestie. Maecenas sed sagittis est.</p>',
          contentValign: 'start',
          colText: 6,
          colImg: 6,
          backgroundColor: '#E0E0E0',
          images: ['kép1', 'kép2', 'kép3'],
          isFluid: true,
          order: 'imgToRight',
          textAlign: 'left',
          useVideo: true,
          video: [
            {
              url: 'TMX20220623.mp4',
              type: 'mp4',
            },
            {
              url: 'TMX20220623.webm',
              type: 'webm',
            },
          ],
        };
      },
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    onButtonClick() {
      if (this.contentBox.button.isExternal) {
        window.open(this.contentBox.button.link_url, '_blank');
      } else {
        this.$router.push({ name: this.contentBox.button.link_url });
      }
    },
  },
};
</script>

<style scoped>

</style>
