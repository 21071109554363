<template>
  <v-container fluid class="pa-0">
    <Slider1 :slider-content="slider"/>
    <!--<SpaceHorizontal :height="5" />
    <Headline2 title="HOGY MIÉRT VÁLASSZ MINKET?"
               title-size="h4"
               :subtitle="null" />
    <IconBox2 :background-bottom-color="iconBox1.backgroundBottomColor"
              :background-top-color="iconBox1.backgroundTopColor"
              :box-col-sm="4"
              :boxes="iconBox1.boxes" />
    <SpaceHorizontal :height="5" />
    <Headline2 title="A FOLYAMAT RÖVIDEN"
               title-size="h4"
               subtitle="A kalkuláció teljes folyamata nem tart tovább 1,5 percnél!"
               subtitle-size="h6" />
    <SpaceHorizontal :height="5" />
    <SideBySideList :list-items="sideBySide.listItems"
                    :image="sideBySide.image"
                    :image-link="sideBySide.imageLink" />
    <SpaceHorizontal :height="5" />
    <ContentBox1 :content-box="contentBox1" />
    <SpaceHorizontal :height="5" />
    <ContentBox1 :content-box="contentBox2" />
    <SpaceHorizontal :height="5" />
    <IconBox2 :background-bottom-color="iconBox2.backgroundBottomColor"
              :background-top-color="iconBox2.backgroundTopColor"
              :boxes="iconBox2.boxes"
              :button="iconBox2.button"
              :box-col-md="iconBox2.boxColMd"
              :dark="true" title-size="h3"/>
    <SpaceHorizontal :height="5" />
    <SlideGroup :list-items="slideGroup" />
    <SpaceHorizontal :height="5" />-->
  </v-container>
</template>

<script>
import DeviceFinder from 'src/components/device/DeviceFinder.vue';
import ContentBox1 from 'src/components/general/ContentBox1.vue';
import IconBox2 from 'src/components/general/IconBox2.vue';
import SpaceHorizontal from 'src/components/general/SpaceHorizontal.vue';
import Headline2 from 'src/components/headlines/Headline2.vue';
import InnerNavBar from 'src/components/navigation/InnerNavBar.vue';
import SideBySideList from 'src/components/general/SideBySideList.vue';
import Slider1 from 'src/components/slider/Slider1.vue';
import SlideGroup from 'src/components/general/SlideGroup.vue';

export default {
  name: 'Home',
  components: {
    IconBox2,
    ContentBox1,
    SpaceHorizontal,
    Headline2,
    DeviceFinder, InnerNavBar, SideBySideList, Slider1, SlideGroup,
  },
  props: {
    //
  },
  data() {
    return {
      contentBox1: {
        button: {
          align: 'left',
          isExternal: false,
          isVisible: false,
          link_url: 'Home',
          text: 'Érdekel',
        },
        content: '<p class="text-h5 font-weight-bold">Összetört a készüléked, aminek nem gazdaságos a javítása?</p>'
          + '<p>Semmi gond. Mi értékeljük a törött, sérült készülékeket is. A kalkulátorunk '
          + 'segítségével nézd meg mennyit fizetünk érte.</p>',
        contentValign: 'center',
        colText: 6,
        colImg: 6,
        backgroundColor: 'transparent',
        images: ['images/amorf1.png'],
        isFluid: true,
        order: 'imgToLeft',
        textAlign: 'left',
        useVideo: false,
        video: [],
      },
      contentBox2: {
        button: {
          align: 'left',
          isExternal: false,
          isVisible: false,
          link_url: 'Home',
          text: 'Érdekel',
        },
        content: '<p class="text-h5 font-weight-bold">Otthon porosodik régi, nem használt készüléked?</p>'
          + '<p>A kalkuláció segítségével nézd meg mennyit ér most a készüléked. Ha szimpatikusnak találtad '
          + 'az ajánlatot, mi elhozzuk a készüléket és utaljuk a vételárat.</p>',
        contentValign: 'center',
        colText: 6,
        colImg: 6,
        backgroundColor: 'transparent',
        images: ['images/amorf2.png'],
        isFluid: true,
        order: 'imgToRight',
        textAlign: 'left',
        useVideo: false,
        video: [],
      },
      iconBox1: {
        backgroundBottomColor: 'transparent',
        backgroundTopColor: 'transparent',
        boxes: [
          {
            description: 'Minden megvásárolt készülék rendszerét gyári állapotra visszaállítjuk, törölve róla '
              + 'minden adatot. Nyugodt lehetsz, adataid nem fognak harmadik félhez kerülni.',
            icon: 'shield-crown',
            title: 'Biztonságos',
          },
          {
            description: 'Ahogy beérkezett a készüléked, ellenőrizzük és 48 órán belül utaljuk a vételárát.',
            icon: 'clock-fast',
            title: 'Gyors és egyszerű',
          },
          {
            description: 'A megvásárolt készüléket megjavítjuk, felújítjuk annak érdekében, hogy visszakerüljenek '
              + 'a körfogásba. Mindezzel közösen hozzájárulunk a környezetvédelemhez.',
            icon: 'leaf',
            title: 'Zöld',
          },
        ],
      },
      iconBox2: {
        backgroundBottomColor: '#7F51D8',
        backgroundTopColor: '#3E90EA',
        boxes: [
          {
            description: 'Kalkulációk száma',
            icon: 'calculator',
            title: '5676',
          },
          {
            description: 'Megvásárolt készülékek száma',
            icon: 'devices',
            title: '4621',
          },
          {
            description: 'Feltámasztott készülékek száma',
            icon: 'cellphone-check',
            title: '883',
          },
          {
            description: 'Elégedett ügyfél',
            icon: 'emoticon-excited-outline',
            title: '4621',
          },
        ],
        button: {
          align: 'center',
          isExternal: false,
          isVisible: false,
          link_params: { partnerName: 'kalkulacio' },
          link_url: 'partnerHome-cashback',
          text: 'Kalkuláció',
        },
        boxColMd: 3,
      },
      partnerContainer: {
        title: 'Válaszd ki, melyik partnerünknél szeretnél új Galaxy Z Fold3 vagy Galaxy Z Flip3 készüléket vásárolni!',
        description: 'A kedvezményt különböző módon tudod érvényesíteni. Válaszd ki a kiszemelt online üzletet és '
          + 'végig vezetünk, hogy néhány egyszerű lépésben kihasználd a Samsung által kínált promóciós kedvezményt.',
      },
      sideBySide: {
        listItems: [
          {
            description: 'Először kérjük tőled a készüléked típusát és IMEI számát.',
            title: 'Alapadatok megadása',
          },
          {
            description: 'Második pontban kérjük a készülék állapotára vonatkozó információkat.',
            title: 'Állapot meghatározása',
          },
          {
            description: 'Amennyiben szimpatikusnak találtad a kalkulált értéket, nincs más dolgod mint elfogadni '
              + 'azt, és várni a futárunkat.',
            title: 'Indikatív ár elfogadása',
          },
          {
            description: 'Tapasztalt szervitechnikusaink bevizsgálják a készüléket és összehasonlítják annak '
              + 'állapotát azzal, amit te adtál meg korábban.Ha lényegi eltérést tapasztalunk, emailben '
              + 'módosított ajánlatot küldünk neked.',
            title: 'Bevizsgálás',
          },
          {
            description: 'Amennyiben mindent rendben találtunk egy végső jóváhagyást kérünk tőled, '
              + 'illetve az utalási adatokat.',
            title: 'Ajánlat elfogadása',
          },
          {
            description: 'Elutaljuk a vételárat és emailben megküldjük az adásvételi szerződést.',
            title: 'Utalás',
          },
        ],
        image: 'images/mammobil1.png',
        imageLink: {
          external: false,
          path: '',
          name: 'HogyanMukodikPage',
        },
      },
      slider: {
        backgroundBottomColor: '#3E90EA',
        backgroundTopColor: '#7F51D8',
        button: {
          align: 'left',
          isExternal: false,
          isVisible: false,
          link_url: '',
          link_params: { partnerName: 'kalkulacio' },
          text: '',
        },
        description: '',
        mob_img_path: 'images/mammobil2.png',
        order: 'imgToRight',
        pc_img_path: 'images/mammobil2.png',
        subtitle: '<div style="margin-bottom: 16px;">Tájékoztatjuk Önöket, hogy a TMX Mobile Solution Szerviz Kft.<br>2023. október 24-én megszüntette a '
          + 'weboldalon nyújtott szolgáltatását!</div>A már leadott igényeket természetesen feldolgozzuk és kezelni fogjuk!<br>'
          + 'Érdeklődni a leadott igényekkel kapcsolatban az <b>info@tmx.hu</b> e-mail címen tudnak.',
        textAlign: 'text-left',
        title: this.$vuetify.breakpoint.smAndDown ? 'Figyelem!' :
          'Figyelem!',
        titleUppercase: true,
        titleWeight: 'bold',
      },
      slideGroup: [
        'shared/pic/brandlogos/alcatel.png',
        'shared/pic/brandlogos/apple.png',
        'shared/pic/brandlogos/asus.png',
        'shared/pic/brandlogos/cat.png',
        'shared/pic/brandlogos/gopro.png',
        'shared/pic/brandlogos/honor.png',
        'shared/pic/brandlogos/huawei.png',
        'shared/pic/brandlogos/lg.png',
        'shared/pic/brandlogos/oppo400x400.png',
        'shared/pic/brandlogos/samsung400x400.png',
        'shared/pic/brandlogos/xiaomi2022.png',
      ],
    };
  },
  methods: {
    //
  },
};
</script>
