<template>
  <v-container class="my-10">
    <v-row>
      <v-col cols="12">
        <div :class="`${titleColor}--text text-${titleSize} text-${textAlign}`" class="font-weight-bold mb-4">
          {{ $t(`${title}`) }}
        </div>
        <p v-if="subtitle" :class="`${subtitleColor}--text text-${subtitleSize} text-${textAlign}`">
          {{ $t(`${subtitle}`) }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Headline2',
  components: {
    //
  },
  props: {
    subtitle: {
      type: String,
      default: 'subtitle',
    },
    subtitleColor: {
      type: String,
      default: 'default',
    },
    subtitleSize: {
      type: String,
      default: 'h4',
    },
    textAlign: {
      type: String,
      default: 'center',
    },
    title: {
      type: String,
      default: 'title',
    },
    titleColor: {
      type: String,
      default: 'default',
    },
    titleSize: {
      type: String,
      default: 'h2',
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
