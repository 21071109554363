<template>
  <v-container fluid class="pa-0"
               :style="`background: ${sliderContent.backgroundTopColor}; background: linear-gradient(to bottom, ${sliderContent.backgroundTopColor}, ${sliderContent.backgroundBottomColor});`">
    <v-container class="py-10 px-6">
      <v-row align="center" class="white--text">
        <v-col cols="12" sm="8" md="6" lg="7" :order="sliderContent.order === 'imgToRight' ? 1 : 2">
          <div :class="sliderContent.titleUppercase ? 'text-uppercase' : ''">
            <div :class="$vuetify.breakpoint.smAndDown ? 'text-h4' : `text-h3`">
              <div v-html="sliderContent.title"
                   :class="`${sliderContent.textAlign} font-weight-${sliderContent.titleWeight} mb-4`"></div>
            </div>
          </div>
          <div v-html="sliderContent.subtitle"
               :class="`text-h5 ${sliderContent.textAlign} mb-8`"></div>
          <div v-html="sliderContent.description"
               :class="`text-h6 ${sliderContent.textAlign} mb-6 font-weight-light`"></div>
          <v-btn v-if="sliderContent.button.isVisible"
                 color="white"
                 outlined
                 x-large
                 rounded
                 @click="onButtonClick">
            {{ sliderContent.button.text }}
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4" md="6" lg="5" :order="sliderContent.order === 'imgToRight' ? 2 : 1">
          <div>
            <v-img :src="`${publicPath}${sliderContent.pc_img_path}`"
                   class="mx-auto"
                   width="100%"
                   max-width="600"
                   aspect-ratio="1"
                   contain />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'Slider1',
  components: {
    //
  },
  props: {
    sliderContent: {
      type: Object,
      default() {
        return {
          backgroundBottomColor: '#F5F5F5',
          backgroundTopColor: '#FAFAFA',
          button: {
            align: 'left',
            isExternal: false,
            isVisible: true,
            link_url: 'Home',
            link_params: null,
            text: 'Érdekel',
          },
          description: 'Számíttasd be régi készülékedet a promóció időtartama alatt, és Galaxy A33 vásárlása esetén '
            + 'extra 10.000 Ft-ot adunk a kiszámított vételáron felül régi készülékedért!',
          mob_img_path: 'images/samsung/mainSlider2022A33MOB.jpg',
          order: 'imgToRight', // imgToLeft
          pc_img_path: 'images/samsung/mainSlider2022A33PC.jpg',
          subtitle: 'Készülékbeszámítási promóció',
          textAlign: 'text-left', // text-right, text-center
          title: 'Cseréld le régi okostelefonod!',
          titleUppercase: false,
          titleWeight: 'regular', // bold, medium
        };
      },
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    onButtonClick() {
      if (this.sliderContent.button.isExternal) {
        window.open(this.sliderContent.button.link_url, '_blank');
      } else {
        if (this.sliderContent.button.link_params) {
          this.$router.push({ name: this.sliderContent.button.link_url, params: this.sliderContent.button.link_params });
        } else {
          this.$router.push({ name: this.sliderContent.button.link_url });
        }
      }
    },
  },
  computed: {
    actionType() {
      return this.$route.meta.bbType;
    },
  },
};
</script>

<style scoped>

</style>
