<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="7" md="auto">
        <v-list max-width="400">
          <template v-for="(aItem, aIndex) in listItems">
            <v-list-item v-if="aIndex < 3" :key="aIndex" class="mb-6">
              <v-list-item-content>
                <v-list-item-title class="text-h6 font-weight-bold mb-3">
                  {{ aItem.title }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-body-2">
                  {{ aItem.description }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar class="primary white--text text-h6 ml-3 align-self-start"
                                  size="56">
                {{ aIndex + 1}}
              </v-list-item-avatar>
            </v-list-item>
          </template>
        </v-list>
      </v-col>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-col cols="12" md="auto">
          <div class="fill-height">
            <v-hover v-slot="{ hover }">
              <v-img :src="`${publicPath}${image}`"
                     height="100%"
                     max-width="254"
                     contain
                     class="cursor-pointer zoomObj"
                     :class="hover ? 'zoomObjHover' : ''"
                     @click="onImageClick(imageLink)"/>
            </v-hover>
          </div>
        </v-col>
      </template>
      <v-col cols="12" sm="7" md="auto">
        <v-list max-width="400">
          <template v-for="(bItem, bIndex) in listItems">
            <v-list-item v-if="bIndex > 2" :key="bIndex" class="mb-6">
              <v-list-item-avatar class="primary white--text text-h6 mr-4 align-self-start"
                                  size="56">
                {{ bIndex + 1}}
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-h6 font-weight-bold mb-3">
                  {{ bItem.title }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-body-2">
                  {{ bItem.description }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SideBySideList',
  components: {
    //
  },
  props: {
    listItems: {
      type: Array,
      default() {
        return [
          {
            description: 'Curabitur ut dapibus tortor. Suspendisse neque diam, sagittis vitae sagittis et, '
              + 'semper quis tortor.',
            title: 'Lorem ipsum dolor sit amet',
          },
          {
            description: 'Curabitur ut dapibus tortor. Suspendisse neque diam, sagittis vitae sagittis et, '
              + 'semper quis tortor.',
            title: 'Lorem ipsum dolor sit amet',
          },
          {
            description: 'Curabitur ut dapibus tortor. Suspendisse neque diam, sagittis vitae sagittis et, '
              + 'semper quis tortor.',
            title: 'Lorem ipsum dolor sit amet',
          },
          {
            description: 'Curabitur ut dapibus tortor. Suspendisse neque diam, sagittis vitae sagittis et, '
              + 'semper quis tortor.',
            title: 'Lorem ipsum dolor sit amet',
          },
          {
            description: 'Curabitur ut dapibus tortor. Suspendisse neque diam, sagittis vitae sagittis et, '
              + 'semper quis tortor.',
            title: 'Lorem ipsum dolor sit amet',
          },
          {
            description: 'Curabitur ut dapibus tortor. Suspendisse neque diam, sagittis vitae sagittis et, '
              + 'semper quis tortor.',
            title: 'Lorem ipsum dolor sit amet',
          },
        ];
      },
    },
    image: {
      type: String,
      default: 'images/placeholder3.jpg',
    },
    imageLink: {
      type: Object,
      default() {
        return {
          external: false,
          path: '',
          name: 'HogyanMukodikPage',
        };
      },
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    onImageClick(link) {
      if (link.external) {
        window.open(`${link.path}`, '_blank');
      } else {
        this.$router.push({ name: link.name });
      }
    },
  },
};
</script>

<style scoped>

</style>
